import React from "react"
import Layout from "../components/layout-small"
import { StaticImage } from "gatsby-plugin-image"

export default () => {

  return (
    <Layout layoutClass="index-container ficus-page" title={"Application Ficus"}>

      <section dataTitle="Ficus" className="fixed f1" id="go">
        <div className="intro">
          <h1>Application Ficus</h1>
          <p><span>Parce que nous sommes tous ramifiés à la même source</span></p>
          <p><span>Parce que la croissance passe par des racines solidement ancrées</span></p>
          <p><span>Parce que la vie est une perpétuelle circulation d'éléments vitaux</span></p>
          <p><span className="ficus">Ficus</span> <span>nous connecte les uns aux autres</span></p>
          <p className="web-link"><a href="http://eebulle.minsh.com/">version web</a></p>
        </div>

        <StaticImage src="../images/ficus/ficus-logo.png" alt="team" style={{ margin: 'auto' }} layout="fixed" />

        <a className="badge google"
          href="https://play.google.com/store/apps/details?id=com.minsh.eebulle&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-AC-global-none-all-co-pr-py-PartBadges-Oct1515-1"><img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/images/apps/fr-play-badge-border.png" /></a>
        <a className="badge apple" href="https://itunes.apple.com/us/app/my-eebulle/id1087685429?mt=8">
          <Appstore />
        </a>
        <a className="web" href="http://eebulle.minsh.com/">Version Web</a>
      </section>

      <section data-title="Présentation" className="scrolling s1" id="presentation">
        <div className="container">
          <h2>C'est quoi "Ficus"?</h2>

          <p>
            Ficus, anciennement appellée My EEBulles, est une application communautaire pour mobile (et également disponible
            via un navigateur web)
            destinée aux membres du <a href="https://bullenetwork.ch">Bulle Network</a>. L'application
            permet de suivre toute l'actualité des l'églises du Bulle Network et d'avoir accès à la liste exhaustive de tous
            les événements
            organisés. Elle permet également d'evoyer des messages groupés ou personnels aux membres.
          </p>

          <h2>Pourquoi utiliser Ficus?</h2>

          <p className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 400 400">
              <g>
                <g>
                  <g>
                    <path d="M261.543,166.668h-0.524v-27.582c0-29.719-24.179-53.898-53.896-53.898h-14.239c-29.721,0-53.899,24.18-53.899,53.898     v27.582h-0.524c-8.687,0-15.73,7.047-15.73,15.734v100.313c0,8.689,7.044,15.734,15.73,15.734h123.086     c8.688,0,15.73-7.045,15.73-15.734V182.402C277.273,173.715,270.229,166.668,261.543,166.668z M215.73,246.475     c0,8.688-7.043,15.73-15.73,15.73c-8.688,0-15.73-7.043-15.73-15.73v-19.664c0-8.688,7.042-15.729,15.73-15.729     c8.688,0,15.73,7.043,15.73,15.729V246.475z M230.219,166.668h-60.438v-27.582c0-12.737,10.362-23.098,23.1-23.098h14.239     c12.735,0,23.099,10.36,23.099,23.098V166.668L230.219,166.668z" fill="#C90025" />
                    <path d="M352.987,328.874l1.75-2.132C384.348,290.73,400,246.902,400,200c0-47.661-16.123-92.051-46.63-128.368l-1.699-2.021     l0.917-2.479c1.655-4.473,2.494-9.182,2.494-13.986c0-22.245-18.097-40.342-40.34-40.342c-8.121,0-15.95,2.406-22.644,6.951     l-2.179,1.481l-2.369-1.153C260.23,6.756,230.773,0,200,0c-31.787,0-62.099,7.176-90.095,21.331l-2.526,1.276l-2.26-1.702     c-7.035-5.3-15.419-8.104-24.247-8.104c-22.243,0-40.338,18.097-40.338,40.342c0,5.989,1.288,11.762,3.829,17.157l1.209,2.569     l-1.777,2.215C15.145,110.766,0,153.964,0,200c0,46.902,15.652,90.73,45.262,126.742l1.75,2.132l-1.081,2.537     c-2.128,4.999-3.208,10.311-3.208,15.787c0,22.241,18.097,40.338,40.34,40.338c8.577,0,16.771-2.665,23.7-7.705l2.224-1.616     l2.466,1.219C139.045,393.08,168.837,400,200,400s60.955-6.92,88.546-20.566l2.466-1.219l2.226,1.616     c6.928,5.04,15.123,7.705,23.698,7.705c22.243,0,40.342-18.097,40.342-40.338c0-5.479-1.08-10.788-3.208-15.787L352.987,328.874z      M335.038,307.572l-1.998,2.502l-3.028-1.035c-4.221-1.446-8.621-2.184-13.077-2.184c-22.241,0-40.34,18.098-40.34,40.343     c0,1.385,0.073,2.788,0.214,4.171l0.328,3.184l-2.89,1.383c-23.311,11.145-48.29,16.791-74.248,16.791     s-50.939-5.646-74.248-16.791l-2.89-1.383l0.329-3.184c0.141-1.383,0.214-2.786,0.214-4.171c0-22.245-18.097-40.343-40.34-40.343     c-4.456,0-8.855,0.735-13.077,2.184l-3.028,1.035l-1.998-2.502C40.305,276.685,27.273,239.484,27.273,200     c0-39.093,12.793-75.996,36.996-106.714l1.92-2.438l2.969,0.899c3.796,1.151,7.737,1.734,11.714,1.734     c22.243,0,40.34-18.097,40.34-40.338c0-1.104-0.053-2.265-0.166-3.541l-0.267-3.089l2.778-1.378     c23.921-11.853,49.64-17.863,76.442-17.863c25.153,0,49.433,5.323,72.166,15.818l3.04,1.402l-0.438,3.318     c-0.239,1.821-0.361,3.614-0.361,5.332c0,22.241,18.097,40.338,40.339,40.338c5.085,0,10.059-0.945,14.785-2.808l3.115-1.23     l2.097,2.611c24.497,30.519,37.985,68.854,37.985,107.944C372.727,239.484,359.695,276.686,335.038,307.572z" fill="#C90025" />
                  </g>
                </g>
              </g>
            </svg>
          </p>
          <p>
            <b>C'est chez nous</b> Ficus est plus qu'une application communautaire. C'est NOTRE application.
            Ainsi, tout ce qui ce passe sur Ficus reste privé et dans le cadre de notre communauté. Chaque membre de
            Ficus doit être validé pas un administrateur qui s'assure que tous les participants soit membre de
            l'église.
          </p>

          <p className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 576 576">
              <g>
                <path d="M240,32L240,32c132.549,0,240,86.957,240,194.224S372.549,420.448,240,420.448c-12.729,0-25.223-0.81-37.417-2.355   C151.03,469.44,91.497,478.647,32,480v-12.567c32.126-15.677,58-44.231,58-76.866c0-4.553-0.356-9.023-1.015-13.396   C34.706,341.562,0,287.175,0,226.224C0,118.957,107.452,32,240,32z M498,467.343c0,27.973,18.156,52.449,46,65.886V544   c-51.562-1.159-98.893-9.051-143.57-53.062c-10.57,1.324-21.396,2.021-32.43,2.021c-47.734,0-91.704-12.879-126.807-34.521   c72.336-0.254,140.629-23.428,192.417-65.336c26.105-21.127,46.697-45.914,61.207-73.675C510.199,289.994,518,258.636,518,226.224   c0-5.224-0.225-10.418-0.629-15.584C553.656,240.607,576,281.451,576,326.479c0,52.244-30.078,98.86-77.119,129.383   C498.309,459.608,498,463.44,498,467.343z" fill="#C90025" />
              </g>
            </svg>
          </p>
          <p>
            <b>Participer</b> Avec Ficus, tu peux participer au discussions de la communauté, laisser des messages et
            contacter
            personnelement des membres. Idéal pour faire connaissance et partager ses idées.
          </p>

          <p className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 60.449 60.449" width="512px" height="512px">
              <g>
                <g>
                  <g>
                    <path d="M22.506,23.467c0.016,0.064,0.009,0.068-0.008,0.041c-0.019,0.079-0.035,0.163-0.05,0.264     c0.027-0.175,0.056-0.244,0.081-0.26c-0.006-0.015-0.013-0.039-0.02-0.055C22.508,23.46,22.506,23.464,22.506,23.467z" fill="#C90025" />
                    <path d="M50.031,25.398C45.79,19.087,39.99,14.794,33.018,12.174c-3.389-0.881-7.825,1.329-7.917,1.437     c-0.227,0.076-0.455,0.174-0.686,0.316c-0.72,0.446-1.173,1.003-1.44,1.609c-1.076,0.637-2.197,1.192-3.397,1.582     c-1.291,0.419-2.589,0.47-3.284,0.062c0.041,0.023-0.155-0.132-0.276-0.486c-0.221-0.651-0.16-1.235-0.11-1.949     c0.149-2.176,1.365-4.385,2.393-6.244c1.856-3.357-3.279-6.355-5.134-3c-2.507,4.537-5.383,11.617-0.962,15.974     c3.415,3.363,8.506,1.978,12.572-0.159c0.199,0.313,0.413,0.619,0.659,0.909c0.27,0.316,0.518,0.643,0.77,0.968     c1.339,2.29,1.389,8.149,1.389,8.149s-4.666,15.936-4.28,24.537c0.235,5.25,8.412,5.273,8.174,0     c-0.295-6.594,0.544-12.914,2.685-19.059c0.386,0.174,0.785,0.311,1.195,0.408c0.373,6.896,1.438,13.709,3.449,20.354     c1.52,5.033,9.41,2.888,7.881-2.172c-2.293-7.597-3.271-15.269-3.321-23.188c-0.002-0.604-0.378-2.367-0.462-2.75     c-0.422-1.92-1.018-3.753-1.762-5.514c1.377,1.313,2.634,2.784,3.742,4.437C47.026,31.563,52.178,28.589,50.031,25.398z" fill="#C90025" />
                    <circle cx="24.8" cy="6.091" r="6.091" fill="#C90025" />
                  </g>
                </g>
              </g>
            </svg>
          </p>
          <p>
            <b>S'informer</b> Nous utilisons l'application pour communiquer des informations importantes et nouvelles. Un
            moyen de plus pour être sûr de ne rien manquer!
          </p>

          <p className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 510 510">
              <g>
                <g id="favorite">
                  <path d="M255,489.6l-35.7-35.7C86.7,336.6,0,257.55,0,160.65C0,81.6,61.2,20.4,140.25,20.4c43.35,0,86.7,20.4,114.75,53.55    C283.05,40.8,326.4,20.4,369.75,20.4C448.8,20.4,510,81.6,510,160.65c0,96.9-86.7,175.95-219.3,293.25L255,489.6z" fill="#C90025" />
                </g>
              </g>
            </svg>
          </p>
          <p>
            <b>Relationner</b> Le Bulle Network est une grande famille qui ne se voit pas que le dimanche matin. Utiliser
            l'application
            c'est aussi développer nos liens et faire grandir l'amour fraternel qui nous unis. Il est facile d'envoyer un
            message d'encouragement, laisser un commentaire amical ou simplement parcourir le fil d'actualité.
          </p>

          <h2>Liens de téléchargement</h2>
          <p>Pour les Iphones: <a
            href="https://play.google.com/store/apps/details?id=com.minsh.eebulle&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-AC-global-none-all-co-pr-py-PartBadges-Oct1515-1">télécharger</a>
          </p>
          <p>Pour les Androïdes: <a href="https://itunes.apple.com/us/app/my-eebulle/id1087685429?mt=8">télécharger</a>
          </p>
          <p></p>
        </div>
      </section>
    </Layout >
  )
}

const Appstore = () => {
  return (
    <svg version="1.1" id="FR_Download_on_the_App_Store"
      xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 135 40">
      <g>
        <path fill="#A6A6A6" d="M130.229,40H4.76C2.153,40,0,37.872,0,35.267V4.726C0,2.12,2.153,0,4.76,0h125.468
		C132.834,0,135,2.12,135,4.726v30.541C135,37.872,132.834,40,130.229,40L130.229,40z"/>
        <path d="M134.032,35.268c0,2.116-1.714,3.83-3.834,3.83H4.729c-2.119,0-3.839-1.714-3.839-3.83V4.725
		c0-2.115,1.72-3.835,3.839-3.835h125.468c2.121,0,3.834,1.72,3.834,3.835L134.032,35.268L134.032,35.268z"/>
        <g>
          <g>
            <path fill="#FFFFFF" d="M30.159,19.784c-0.029-3.223,2.639-4.791,2.761-4.864c-1.511-2.203-3.853-2.504-4.676-2.528
				c-1.967-0.207-3.875,1.177-4.877,1.177c-1.022,0-2.565-1.157-4.228-1.123c-2.14,0.033-4.142,1.272-5.24,3.196
				c-2.266,3.923-0.576,9.688,1.595,12.859c1.086,1.553,2.355,3.287,4.016,3.226c1.625-0.067,2.232-1.036,4.193-1.036
				c1.943,0,2.513,1.036,4.207,0.997c1.744-0.028,2.842-1.56,3.89-3.127c1.255-1.78,1.759-3.533,1.779-3.623
				C33.539,24.924,30.192,23.647,30.159,19.784z"/>
            <path fill="#FFFFFF" d="M26.959,10.306c0.874-1.093,1.472-2.58,1.306-4.089C27,6.273,25.417,7.091,24.507,8.161
				c-0.806,0.942-1.526,2.486-1.34,3.938C24.588,12.205,26.047,11.382,26.959,10.306z"/>
          </g>
        </g>
        <g>
          <path fill="#FFFFFF" d="M45.175,31.504h-1.944V18.742h1.944V31.504z" />
          <path fill="#FFFFFF" d="M50.251,19.048c-0.516,1.812-1.128,3.3-1.836,4.464l-1.206,0.126c0.528-1.44,0.906-2.91,1.134-4.41
			L50.251,19.048z"/>
          <path fill="#FFFFFF" d="M59.773,31.504h-2.07l-1.134-3.563h-3.942l-1.08,3.563h-2.016l3.906-12.132h2.412L59.773,31.504z
			 M56.227,26.445l-1.026-3.168c-0.108-0.323-0.312-1.086-0.612-2.286h-0.036c-0.12,0.516-0.312,1.278-0.576,2.286l-1.008,3.168
			H56.227z"/>
          <path fill="#FFFFFF" d="M69.816,27.021c0,1.488-0.402,2.664-1.207,3.528c-0.72,0.769-1.614,1.152-2.682,1.152
			c-1.152,0-1.98-0.414-2.484-1.242h-0.036v4.608h-1.944v-9.433c0-0.937-0.024-1.896-0.072-2.88h1.71l0.108,1.387h0.036
			c0.648-1.045,1.632-1.566,2.952-1.566c1.032,0,1.893,0.408,2.583,1.224C69.471,24.616,69.816,25.689,69.816,27.021z
			 M67.836,27.094c0-0.852-0.192-1.554-0.576-2.105c-0.42-0.576-0.984-0.864-1.692-0.864c-0.48,0-0.916,0.159-1.305,0.478
			c-0.39,0.317-0.645,0.734-0.765,1.25c-0.06,0.24-0.09,0.438-0.09,0.594v1.458c0,0.637,0.195,1.174,0.585,1.611
			c0.39,0.438,0.897,0.657,1.521,0.657c0.732,0,1.302-0.282,1.71-0.846C67.632,28.762,67.836,28.019,67.836,27.094z"/>
          <path fill="#FFFFFF" d="M79.879,27.021c0,1.488-0.402,2.664-1.207,3.528c-0.719,0.769-1.613,1.152-2.682,1.152
			c-1.152,0-1.98-0.414-2.484-1.242h-0.035v4.608h-1.945v-9.433c0-0.937-0.023-1.896-0.07-2.88h1.709l0.107,1.387h0.037
			c0.648-1.045,1.631-1.566,2.951-1.566c1.033,0,1.893,0.408,2.584,1.224C79.533,24.616,79.879,25.689,79.879,27.021z
			 M77.898,27.094c0-0.852-0.191-1.554-0.576-2.105c-0.42-0.576-0.984-0.864-1.691-0.864c-0.48,0-0.916,0.159-1.305,0.478
			c-0.391,0.317-0.646,0.734-0.766,1.25c-0.061,0.24-0.09,0.438-0.09,0.594v1.458c0,0.637,0.195,1.174,0.584,1.611
			c0.391,0.438,0.898,0.657,1.521,0.657c0.732,0,1.303-0.282,1.711-0.846C77.693,28.762,77.898,28.019,77.898,27.094z"/>
          <path fill="#FFFFFF" d="M91.127,28.103c0,1.031-0.359,1.871-1.076,2.52c-0.791,0.708-1.893,1.062-3.305,1.062
			c-1.307,0-2.354-0.252-3.145-0.756l0.449-1.62c0.852,0.517,1.789,0.774,2.809,0.774c0.732,0,1.303-0.165,1.711-0.496
			c0.408-0.33,0.613-0.772,0.613-1.325c0-0.493-0.168-0.907-0.504-1.244c-0.336-0.336-0.895-0.649-1.674-0.938
			c-2.125-0.793-3.188-1.953-3.188-3.479c0-0.998,0.373-1.815,1.117-2.452c0.744-0.637,1.734-0.956,2.971-0.956
			c1.104,0,2.021,0.192,2.754,0.576l-0.486,1.583c-0.684-0.372-1.459-0.558-2.322-0.558c-0.684,0-1.219,0.168-1.602,0.504
			c-0.324,0.3-0.486,0.666-0.486,1.099c0,0.479,0.186,0.875,0.559,1.188c0.322,0.288,0.91,0.6,1.764,0.936
			c1.043,0.42,1.811,0.912,2.303,1.477S91.127,27.262,91.127,28.103z"/>
          <path fill="#FFFFFF" d="M97.553,24.214h-2.141v4.248c0,1.08,0.377,1.62,1.133,1.62c0.348,0,0.637-0.03,0.865-0.09l0.053,1.476
			c-0.383,0.145-0.887,0.216-1.512,0.216c-0.768,0-1.367-0.233-1.799-0.702c-0.434-0.468-0.648-1.253-0.648-2.357v-4.41h-1.279
			v-1.458h1.279v-1.602l1.908-0.576v2.178h2.141V24.214z"/>
          <path fill="#FFFFFF" d="M107.219,27.059c0,1.343-0.385,2.447-1.152,3.312c-0.805,0.888-1.871,1.332-3.203,1.332
			c-1.285,0-2.309-0.426-3.07-1.278c-0.762-0.852-1.143-1.926-1.143-3.222c0-1.356,0.393-2.466,1.18-3.33
			c0.785-0.864,1.844-1.296,3.176-1.296c1.285,0,2.316,0.426,3.096,1.277C106.846,24.682,107.219,25.75,107.219,27.059z
			 M105.203,27.121c0-0.807-0.174-1.498-0.523-2.076c-0.408-0.697-0.988-1.047-1.746-1.047c-0.779,0-1.373,0.35-1.781,1.047
			c-0.348,0.578-0.521,1.281-0.521,2.111c0,0.808,0.174,1.499,0.521,2.076c0.42,0.698,1.008,1.048,1.764,1.048
			c0.744,0,1.326-0.355,1.746-1.065C105.021,28.625,105.203,27.927,105.203,27.121z"/>
          <path fill="#FFFFFF" d="M113.535,24.466c-0.191-0.036-0.395-0.054-0.611-0.054c-0.684,0-1.213,0.258-1.584,0.773
			c-0.324,0.457-0.486,1.033-0.486,1.729v4.59h-1.943l0.018-5.994c0-1.008-0.023-1.926-0.072-2.754h1.693l0.07,1.674h0.055
			c0.205-0.576,0.527-1.037,0.973-1.386c0.432-0.312,0.9-0.468,1.404-0.468c0.18,0,0.342,0.012,0.484,0.035V24.466z"/>
          <path fill="#FFFFFF" d="M122.23,26.716c0,0.349-0.025,0.642-0.072,0.882h-5.832c0.023,0.864,0.307,1.524,0.846,1.98
			c0.492,0.408,1.129,0.611,1.908,0.611c0.863,0,1.65-0.138,2.357-0.413l0.307,1.35c-0.828,0.36-1.807,0.54-2.934,0.54
			c-1.357,0-2.422-0.398-3.195-1.197c-0.773-0.798-1.16-1.869-1.16-3.213c0-1.32,0.359-2.418,1.078-3.294
			c0.758-0.937,1.777-1.403,3.061-1.403c1.26,0,2.215,0.467,2.863,1.403C121.973,24.706,122.23,25.624,122.23,26.716z
			 M120.377,26.212c0.012-0.576-0.115-1.073-0.379-1.494c-0.336-0.54-0.852-0.81-1.549-0.81c-0.635,0-1.15,0.264-1.547,0.791
			c-0.324,0.421-0.516,0.925-0.576,1.513H120.377z"/>
        </g>
        <g>
          <g>
            <path fill="#FFFFFF" d="M47.84,7.778h-1.872v5.655H44.92V7.778h-1.862V6.896h4.782V7.778z" />
            <path fill="#FFFFFF" d="M52.981,10.853c0,0.188-0.014,0.346-0.039,0.475h-3.143c0.013,0.466,0.164,0.821,0.455,1.067
				c0.266,0.22,0.608,0.33,1.028,0.33c0.466,0,0.89-0.074,1.271-0.223l0.164,0.728c-0.446,0.194-0.973,0.291-1.581,0.291
				c-0.73,0-1.305-0.215-1.722-0.645s-0.625-1.007-0.625-1.731c0-0.711,0.193-1.303,0.582-1.775
				c0.407-0.504,0.956-0.756,1.648-0.756c0.679,0,1.193,0.252,1.542,0.756C52.841,9.77,52.981,10.265,52.981,10.853z M51.981,10.582
				c0.007-0.311-0.062-0.579-0.203-0.805c-0.182-0.291-0.459-0.437-0.834-0.437c-0.343,0-0.621,0.142-0.835,0.427
				c-0.174,0.227-0.277,0.498-0.31,0.815H51.981z M52.409,6.74l-1.174,1.406h-0.728l0.844-1.406H52.409z"/>
            <path fill="#FFFFFF" d="M55.55,13.433h-1.048V6.556h1.048V13.433z" />
            <path fill="#FFFFFF" d="M61.293,10.853c0,0.188-0.014,0.346-0.039,0.475h-3.143c0.013,0.466,0.164,0.821,0.455,1.067
				c0.266,0.22,0.608,0.33,1.028,0.33c0.466,0,0.89-0.074,1.271-0.223l0.164,0.728c-0.446,0.194-0.973,0.291-1.581,0.291
				c-0.73,0-1.305-0.215-1.722-0.645s-0.625-1.007-0.625-1.731c0-0.711,0.193-1.303,0.582-1.775
				c0.407-0.504,0.956-0.756,1.648-0.756c0.679,0,1.193,0.252,1.542,0.756C61.154,9.77,61.293,10.265,61.293,10.853z M60.293,10.582
				c0.007-0.311-0.062-0.579-0.203-0.805c-0.182-0.291-0.459-0.437-0.834-0.437c-0.343,0-0.621,0.142-0.835,0.427
				c-0.174,0.227-0.277,0.498-0.31,0.815H60.293z M60.721,6.74l-1.174,1.406H58.82l0.844-1.406H60.721z"/>
            <path fill="#FFFFFF" d="M66.181,8.845l-0.203,0.795c-0.266-0.129-0.569-0.194-0.912-0.194c-0.459,0-0.823,0.154-1.092,0.461
				c-0.268,0.307-0.402,0.697-0.402,1.169c0,0.498,0.141,0.892,0.422,1.183s0.639,0.437,1.072,0.437c0.323,0,0.64-0.064,0.95-0.194
				l0.146,0.786c-0.343,0.162-0.775,0.243-1.3,0.243c-0.718,0-1.29-0.218-1.717-0.655s-0.64-1.02-0.64-1.751
				c0-0.73,0.229-1.331,0.688-1.799s1.076-0.703,1.853-0.703C65.486,8.622,65.865,8.696,66.181,8.845z"/>
            <path fill="#FFFFFF" d="M71.709,13.433h-1.047v-2.68c0-0.845-0.316-1.268-0.951-1.268c-0.484,0-0.818,0.245-0.999,0.735
				c-0.032,0.103-0.049,0.229-0.049,0.377v2.835h-1.048V6.556h1.048v2.841h0.02c0.331-0.517,0.801-0.775,1.417-0.775
				c0.434,0,0.791,0.142,1.076,0.427c0.355,0.355,0.533,0.883,0.533,1.581V13.433z"/>
            <path fill="#FFFFFF" d="M77.084,13.433h-0.941l-0.078-0.543h-0.029c-0.322,0.433-0.781,0.65-1.377,0.65
				c-0.445,0-0.805-0.143-1.076-0.427c-0.246-0.258-0.369-0.579-0.369-0.96c0-0.576,0.24-1.015,0.723-1.319
				c0.482-0.304,1.16-0.453,2.033-0.446V10.3c0-0.621-0.326-0.931-0.979-0.931c-0.465,0-0.875,0.117-1.229,0.349l-0.213-0.688
				c0.438-0.271,0.979-0.407,1.617-0.407c1.232,0,1.85,0.65,1.85,1.95v1.736C77.016,12.78,77.039,13.155,77.084,13.433z
				 M75.996,11.813v-0.727c-1.156-0.02-1.734,0.297-1.734,0.95c0,0.246,0.066,0.43,0.201,0.553c0.135,0.123,0.307,0.184,0.512,0.184
				c0.23,0,0.445-0.073,0.641-0.218c0.197-0.146,0.318-0.331,0.363-0.558C75.99,11.946,75.996,11.884,75.996,11.813z"/>
            <path fill="#FFFFFF" d="M81.342,9.641c-0.104-0.02-0.213-0.029-0.33-0.029c-0.367,0-0.652,0.139-0.854,0.417
				c-0.174,0.246-0.262,0.556-0.262,0.931v2.474H78.85l0.01-3.23c0-0.543-0.014-1.038-0.039-1.484h0.912l0.039,0.902h0.029
				c0.109-0.311,0.283-0.56,0.523-0.747c0.232-0.168,0.484-0.252,0.756-0.252c0.098,0,0.186,0.006,0.262,0.019V9.641z"/>
            <path fill="#FFFFFF" d="M86.812,8.719c-0.025,0.382-0.039,0.828-0.039,1.339v2.696c0,1.015-0.227,1.727-0.678,2.134
				c-0.414,0.375-0.996,0.562-1.746,0.562c-0.654,0-1.182-0.123-1.582-0.369l0.242-0.805c0.395,0.239,0.842,0.359,1.34,0.359
				c0.924,0,1.387-0.498,1.387-1.494v-0.456h-0.02c-0.291,0.479-0.754,0.718-1.387,0.718c-0.57,0-1.039-0.216-1.406-0.65
				c-0.369-0.433-0.553-0.983-0.553-1.649c0-0.756,0.213-1.368,0.639-1.833c0.395-0.433,0.877-0.65,1.445-0.65
				c0.641,0,1.1,0.249,1.379,0.747h0.018l0.039-0.65H86.812z M85.727,11.416v-0.834c0-0.317-0.1-0.588-0.297-0.815
				c-0.199-0.227-0.459-0.339-0.785-0.339c-0.357,0-0.65,0.148-0.881,0.446c-0.23,0.298-0.346,0.695-0.346,1.193
				c0,0.453,0.107,0.818,0.322,1.096c0.219,0.304,0.521,0.456,0.904,0.456c0.234,0,0.443-0.069,0.629-0.208
				c0.186-0.139,0.316-0.328,0.395-0.567C85.707,11.726,85.727,11.583,85.727,11.416z"/>
            <path fill="#FFFFFF" d="M92.516,10.853c0,0.188-0.014,0.346-0.039,0.475h-3.143c0.014,0.466,0.164,0.821,0.455,1.067
				c0.266,0.22,0.609,0.33,1.029,0.33c0.465,0,0.889-0.074,1.271-0.223l0.164,0.728c-0.447,0.194-0.973,0.291-1.582,0.291
				c-0.73,0-1.305-0.215-1.721-0.645c-0.418-0.43-0.625-1.007-0.625-1.731c0-0.711,0.193-1.303,0.582-1.775
				c0.406-0.504,0.955-0.756,1.648-0.756c0.678,0,1.193,0.252,1.541,0.756C92.377,9.77,92.516,10.265,92.516,10.853z M91.516,10.582
				c0.008-0.311-0.061-0.579-0.203-0.805c-0.182-0.291-0.459-0.437-0.834-0.437c-0.342,0-0.621,0.142-0.834,0.427
				c-0.174,0.227-0.277,0.498-0.311,0.815H91.516z"/>
            <path fill="#FFFFFF" d="M96.531,9.641c-0.104-0.02-0.213-0.029-0.33-0.029c-0.369,0-0.652,0.139-0.854,0.417
				c-0.174,0.246-0.262,0.556-0.262,0.931v2.474h-1.049l0.01-3.23c0-0.543-0.012-1.038-0.037-1.484h0.91l0.039,0.902h0.029
				c0.109-0.311,0.285-0.56,0.523-0.747c0.234-0.168,0.486-0.252,0.758-0.252c0.096,0,0.184,0.006,0.262,0.019V9.641z"/>
            <path fill="#FFFFFF" d="M104.893,13.433h-0.932l-0.049-0.757h-0.029c-0.297,0.576-0.801,0.864-1.512,0.864
				c-0.57,0-1.041-0.223-1.416-0.669s-0.562-1.025-0.562-1.736c0-0.763,0.203-1.381,0.609-1.853c0.395-0.44,0.881-0.66,1.455-0.66
				c0.635,0,1.078,0.213,1.33,0.64h0.02V6.556h1.047v5.607C104.854,12.622,104.867,13.045,104.893,13.433z M103.807,11.445v-0.786
				c0-0.136-0.01-0.246-0.029-0.33c-0.059-0.252-0.186-0.464-0.381-0.635s-0.428-0.257-0.701-0.257c-0.389,0-0.695,0.155-0.92,0.466
				s-0.336,0.708-0.336,1.193c0,0.466,0.107,0.844,0.322,1.135c0.227,0.31,0.531,0.465,0.914,0.465c0.344,0,0.621-0.129,0.828-0.388
				C103.705,12.069,103.807,11.781,103.807,11.445z"/>
            <path fill="#FFFFFF" d="M110.246,13.433h-0.941l-0.076-0.543h-0.029c-0.324,0.433-0.783,0.65-1.377,0.65
				c-0.447,0-0.807-0.143-1.078-0.427c-0.246-0.258-0.367-0.579-0.367-0.96c0-0.576,0.24-1.015,0.723-1.319
				c0.48-0.304,1.158-0.453,2.031-0.446V10.3c0-0.621-0.326-0.931-0.977-0.931c-0.465,0-0.875,0.117-1.23,0.349l-0.213-0.688
				c0.439-0.271,0.979-0.407,1.617-0.407c1.234,0,1.85,0.65,1.85,1.95v1.736C110.178,12.78,110.201,13.155,110.246,13.433z
				 M109.16,11.813v-0.727c-1.158-0.02-1.736,0.297-1.736,0.95c0,0.246,0.068,0.43,0.203,0.553c0.135,0.123,0.305,0.184,0.51,0.184
				c0.232,0,0.445-0.073,0.643-0.218c0.195-0.146,0.316-0.331,0.361-0.558C109.154,11.946,109.16,11.884,109.16,11.813z"/>
            <path fill="#FFFFFF" d="M116.105,13.433h-1.049v-2.7c0-0.832-0.316-1.248-0.949-1.248c-0.311,0-0.562,0.114-0.758,0.343
				c-0.193,0.229-0.291,0.499-0.291,0.808v2.796h-1.047v-3.366c0-0.414-0.014-0.863-0.039-1.349h0.922l0.049,0.737h0.029
				c0.121-0.229,0.303-0.418,0.543-0.569c0.283-0.176,0.602-0.265,0.949-0.265c0.441,0,0.807,0.142,1.098,0.427
				c0.361,0.349,0.543,0.87,0.543,1.562V13.433z"/>
            <path fill="#FFFFFF" d="M120.83,12.079c0,0.438-0.162,0.79-0.484,1.055c-0.324,0.265-0.77,0.397-1.34,0.397
				c-0.537,0-0.992-0.107-1.367-0.32l0.223-0.776c0.363,0.22,0.748,0.33,1.154,0.33c0.537,0,0.805-0.197,0.805-0.592
				c0-0.174-0.057-0.318-0.174-0.432c-0.117-0.113-0.324-0.225-0.621-0.334c-0.84-0.311-1.26-0.763-1.26-1.358
				c0-0.407,0.154-0.747,0.465-1.019s0.721-0.407,1.232-0.407c0.465,0,0.863,0.095,1.193,0.285l-0.225,0.753
				c-0.303-0.181-0.623-0.271-0.959-0.271c-0.221,0-0.393,0.052-0.516,0.155c-0.121,0.104-0.184,0.235-0.184,0.397
				c0,0.161,0.064,0.293,0.193,0.396c0.111,0.097,0.324,0.203,0.641,0.319C120.422,10.966,120.83,11.44,120.83,12.079z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}